import { InjectionToken } from '@angular/core'
import { makeStateKey } from '@angular/platform-browser'
import { IFloDDTracer } from 'src/app/server/server-log.config'
import { SchedulerLike } from 'rxjs'
import { EnvConfig } from 'src/environments/environment.interface'

export const ENV_CONFIG = new InjectionToken<EnvConfig>('cfg.env')
export const ENV_CONFIG_TS_KEY = makeStateKey<EnvConfig>('cfg.env.ts')
export const SERVER_CUSTOM_JSON_CONTENT_KEY = 'X-Custom-Render-JSON'
export const DATA_DOG_INSTANCE = new InjectionToken<IFloDDTracer>('app.dd.config')
export const RXJS_SCHEDULER = new InjectionToken<SchedulerLike>('app.rxjs.scheduler')

/**
 * On the web app, we always set this header to 1 on every server side API request.
 * It's up to the BFF, Fastly, etc. to do something advantageous with it,
 * whether that's bypassing Fastly cache or rendering skeletons.
 */
export const FLO_WEB_SSR_HEADER = 'x-flo-web-ssr'
/**
 * These are other custom response headers from Fastly that are set on SSR,
 * and transferred to the client. We can add constants to this list as needed, and
 * use them in the client to make decisions based on the server response.
 */
export const FLO_FLAGS_COOKIE = 'x-flo-ab'
export const FLO_FLAGS_HEADER = 'x-flo-flags'
export const FLO_FLAGS_FORCED_KEY = 'x-flo-ab-forced'
export const FLO_COUNTRY_CODE = 'x-flo-geo-country-code'
export const EXPERIMENT_SEPARATOR = '|'
export const EXPERIMENT_VARIATION_SEPARATOR = '-'

/**
 * The experience API version should be kept roughly in sync with @flocasts/experience-service-types pkg.
 * It's okay if API query param version gets a little behind types pkg version,
 * but they should always be on the same MAJOR version.
 * You may need to bump minor/patch version for getting back JSON responses that include
 * new features and bug fixes.
 */
export const EXPERIENCE_API_VERSION = '1.26.25'
export const EXPERIENCE_API_EVENT_HUB_BASE_PATH = 'experiences/web/event-hub'
export const EXPERIENCE_API_RESULTS_BASE_PATH = 'experiences/web/results/tab'
export const EXPERIENCE_API_WATCH_BASE_PATH = 'experiences/web/watch'
export const EXPERIENCE_API_HOME_BASE_PATH = 'experiences/web/home'
export const EXPERIENCE_API_EVENT_TICKER_PARTIAL_PATH = 'experiences/web/partials/event-ticker'
export const EXPERIENCE_API_SCHEDULE_BASE_PATH = 'experiences/web/schedule/tab'
export const EXPERIENCE_API_RANKINGS_BASE_PATH = 'experiences/web/rankings'
export const EXPERIENCE_API_LEAGUES_BASE_PATH = 'experiences/web/leagues'
export const EXPERIENCE_API_COLLECTION_DETAIL_BASE_BATH = 'experiences/web/collection-detail'
export const EXPERIENCE_API_ENTITY_HUB_BASE_PATH = 'experiences/web/entity-hub'
export const EXPERIENCE_API_NAVIGATION_BASE_PATH = 'experiences/web/partials/navigation'
export const EXPERIENCE_API_OFFERS_BASE_PATH = 'experiences/web/partials/offers'
export const EXPERIENCE_API_VIDEO_PARTIAL_PATH = 'experiences/web/partials/video'
export const EXPERIENCE_API_SUBSCRIPTIONS_PARTIAL_PATH = 'experiences/web/partials/account/subscriptions'
export const EXPERIENCE_API_EMAIL_VALIDATION_PATH = 'experiences/web/funnel/validate'
export const EXPERIENCE_API_SPORTS_BASE_PATH = 'experiences/web/sports'
export const EXPERIENCE_API_CONFERENCES_BASE_PATH = 'experiences/web/conferences'
export const EXPERIENCE_API_EVENTS_LIVE_UPCOMING_PARTIAL_PATH = 'experiences/web/events/live-and-upcoming/partial'
export const EXPERIENCE_API_SEARCH_BASE_PATH = 'experiences/web/search'

/**
 * Core API (legacy) "pass-through" endpoints provided by the Experience Service.
 */
export const EXPERIENCE_API_LEGACY_CORE_ARTICLES_PATH = 'experiences/web/legacy-core/articles'
export const EXPERIENCE_API_LEGACY_CORE_RANKINGS_PATH = 'experiences/web/legacy-core/ranking-containers'
export const EXPERIENCE_API_LEGACY_CORE_SEARCH_PATH = 'experiences/web/legacy-core/search'
export const EXPERIENCE_API_LEGACY_CORE_COLLECTIONS_PATH = 'experiences/web/legacy-core/collections'
export const EXPERIENCE_API_LEGACY_CORE_COLLECTIONS_TAG_PATH = `${EXPERIENCE_API_LEGACY_CORE_COLLECTIONS_PATH}/tag`
export const EXPERIENCE_API_LEGACY_CORE_RECENT_COLLECTIONS_PATH = `${EXPERIENCE_API_LEGACY_CORE_COLLECTIONS_PATH}/recent-published`
export const EXPERIENCE_API_LEGACY_CORE_SEARCH_EVENTS_PATH = 'experiences/web/legacy-core/search/events'
export const EXPERIENCE_API_LEGACY_CORE_RESULTS_PATH = 'experiences/web/legacy-core/result-containers'
export const EXPERIENCE_API_LEGACY_CORE_NODES_PATH = 'experiences/web/legacy-core/nodes'
export const EXPERIENCE_API_LEGACY_CORE_NODES_LIST_PATH = `${EXPERIENCE_API_LEGACY_CORE_NODES_PATH}/list/from-ids`
export const EXPERIENCE_API_LEGACY_CORE_EVENTS_PATH = 'experiences/web/legacy-core/events'
export const EXPERIENCE_API_LEGACY_CORE_LIVE_EVENTS_PATH = 'experiences/web/legacy-core/live-events'
export const EXPERIENCE_API_LEGACY_CORE_VIDEOS_PATH = 'experiences/web/legacy-core/videos'
export const EXPERIENCE_API_LEGACY_CORE_VIDEOS_EMBED_PATH = 'experiences/web/legacy-core/videos/embed'
export const EXPERIENCE_API_LEGACY_CORE_RIGHT_RAIL_VIDEOS_PATH = 'experiences/web/legacy-core/right-rail/videos'
export const EXPERIENCE_API_LEGACY_CORE_FILMS_PATH = 'experiences/web/legacy-core/films'
export const EXPERIENCE_API_LEGACY_CORE_SITES_PATH = 'experiences/web/legacy-core/sites'
export const EXPERIENCE_API_SUBSCRIPTION_LIST_PATH = 'experiences/web/account/subscriptions'

/**
 * TODO: Remove this after we roll out new Athlete pages 100%
 */
export enum ExperienceCollectionDetailSupportedTabs {
  Results = 'results'
}

/**
 * Core API (legacy) endpoints.
 */

export const CORE_API_SURVEYS_PATH = 'surveys'
export const CORE_API_SURVEYS_RESPONSES_PATH = `${CORE_API_SURVEYS_PATH}/responses`
export const CORE_API_STRIPE_SUBSCRIPTIONS_DOWNGRADE_PATH = 'stripe-subscriptions/downgrade'
export const CORE_API_SUBSCRIPTIONS_PATH = 'subscriptions'
export const CORE_API_SUBSCRIPTIONS_REFUND_AND_DISCOUNT_PATH = `${CORE_API_SUBSCRIPTIONS_PATH}/refund-and-discount`
export const CORE_API_SUBSCRIPTIONS_PAYMENT_INTENT_PATH = `${CORE_API_SUBSCRIPTIONS_PATH}/payment-intent`
export const CORE_API_PLANS_PATH = 'plans'
export const CORE_API_USERS_PATH = 'users'
export const CORE_API_USERS_NEWSLETTER_PATH = `${CORE_API_USERS_PATH}/newsletter`
export const CORE_API_FORGOT_PASSWORD_PATH = `${CORE_API_USERS_PATH}/forgot-password`
export const CORE_API_RESET_PASSWORD_PATH = `${CORE_API_USERS_PATH}/reset-password`
export const CORE_API_USERS_RESET_PASSWORD_CHECK_TOKEN_PATH = `${CORE_API_RESET_PASSWORD_PATH}/check-token`
export const CORE_API_USERS_CHECK_USERNAME_EXISTS_PATH = `${CORE_API_USERS_PATH}/check-username-exists`
export const CORE_API_USERS_CHECK_EMAIL_EXISTS_PATH = `${CORE_API_USERS_PATH}/check-email-exists`
export const CORE_API_CLAIM_ACCOUNT_PATH = `claim-account`
export const CORE_API_VIDEOS_PATH = 'videos'
export const CORE_API_COUPON_PATH = 'coupon'
export const CORE_API_ACTIVATION_CODES_PATH = 'activation-codes'
export const CORE_API_REFRESH_TOKENS_PATH = 'refresh-tokens'
export const CORE_API_TOKENS_PATH = 'tokens'

/**
 * This is an easy way to add the site_id query param to an API call.
 * HOWEVER, this is incompatible with the cache wrapper,
 * which we use to prevent SSR flash when querying BFF endpoints
 * for client-specific data like permissions, favorites or formatted dates based on client timezone.
 * If an experience is powered by one of the below endpoints and is
 * also using the cache wrapper, it will likely flash during the client rendering.
 * Endpoints that rely on BFF-powered client-specific data (including tz date formatting)
 * should set the site_id query param in the data service,
 * not via this API_REQUESTS_THAT_NEED_SITE_ID_QUERY_PARAM const and the vertical interceptor.
 *
 * @deprecated The use of API_REQUESTS_THAT_NEED_SITE_ID_QUERY_PARAM is to append a site_id param is deprecated.
 * Instead, manually configure the site_id param in the data service.
 */
export const API_REQUESTS_THAT_NEED_SITE_ID_QUERY_PARAM = [
  EXPERIENCE_API_RESULTS_BASE_PATH,
  EXPERIENCE_API_RANKINGS_BASE_PATH,
  EXPERIENCE_API_EVENTS_LIVE_UPCOMING_PARTIAL_PATH,
  EXPERIENCE_API_LEGACY_CORE_ARTICLES_PATH,
  EXPERIENCE_API_LEGACY_CORE_RANKINGS_PATH,
  EXPERIENCE_API_LEGACY_CORE_SEARCH_PATH,
  EXPERIENCE_API_LEGACY_CORE_COLLECTIONS_TAG_PATH,
  EXPERIENCE_API_LEGACY_CORE_RECENT_COLLECTIONS_PATH,
  EXPERIENCE_API_LEGACY_CORE_SEARCH_EVENTS_PATH,
  EXPERIENCE_API_LEGACY_CORE_COLLECTIONS_PATH,
  EXPERIENCE_API_LEGACY_CORE_RESULTS_PATH,
  EXPERIENCE_API_LEGACY_CORE_NODES_PATH,
  EXPERIENCE_API_LEGACY_CORE_LIVE_EVENTS_PATH,
  EXPERIENCE_API_LEGACY_CORE_VIDEOS_PATH,
  EXPERIENCE_API_LEGACY_CORE_VIDEOS_EMBED_PATH,
  EXPERIENCE_API_LEGACY_CORE_RIGHT_RAIL_VIDEOS_PATH,
  EXPERIENCE_API_LEGACY_CORE_FILMS_PATH
]
/**
 * Custom Headers Array that are set and exposed in Fastly. These headers are passed back with every response.
 * This is meant to be used in the http-api-custom-response-headers interceptor. Or anywhere we want to get
 * custom headers from Fastly. Any new ones we want access too we can add here.
 */
export const API_FASTLY_CUSTOM_HEADERS = [FLO_FLAGS_COOKIE, FLO_FLAGS_HEADER, FLO_FLAGS_FORCED_KEY, FLO_COUNTRY_CODE]

// taken from _variables.scss
export interface IBreakpointsInPixels {
  extraSmall: number
  small: number
  medium: number
  large: number
  extraLarge: number
  readonly [key: string]: number
}
export const breakpointsInPixels: IBreakpointsInPixels = {
  extraSmall: 480,
  small: 576,
  medium: 768,
  large: 992,
  extraLarge: 1200
}
